@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0 !important;
  padding: 0  !important;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-nav{

      padding: 0px 20px !important;

}
.ant-tabs-nav {
  margin-bottom: 0px  !important;
}

.ant-popover-buttons{
  text-align: left !important;
  margin-left: 15px !important;
}

th {
  white-space: nowrap !important;
}
